import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import "./StartTest.css";
import { Alert, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import parse from "html-react-parser";
import { startCandidateAssessment } from "../../Helpers/AsyncCalls";

const StartTest = ({
  user,
  setIsStarted,
  loading,
  assessmentDetails,
  assessmentType,
}) => {
  const [permissionError, setPermissionError] = useState(false);
  const [cameraAccessGranted, setCameraAccessGranted] = useState(false); // State to handle permission error

  useEffect(() => {
    const handleCameraAccess = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setCameraAccessGranted(true);
      } catch (error) {
        console.error("Error accessing media devices:", error);
        setPermissionError(true);
      }
    };

    handleCameraAccess();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsStarted(true);
    if (assessmentType !== "audio") {
      startCandidateAssessment(user.id, assessmentDetails.id);
    }
  };

  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="xl"
        className="StartTestContainer p-0"
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <div className="p-3 shadow-md rounded-sm  bg-white md:p-10 md:w-f startTestBox">
            <h3 className="text-2xl font-medium pb-7 justify-center md:text-3xl ">
              {assessmentDetails?.title}
            </h3>
            <div className="text-left list-disc">
              {parse(assessmentDetails?.description)}
            </div>

            <h3 className="my-3 font-semibold text-left text-gray-500">
              Verify the following details are correct. Grant access to your
              camera, and then proceed by clicking the 'Start the test' button.
            </h3>
            <form action="#" className="w-full mt-3 flex flex-col gap-2 ">
              <div className="w-full mt-3 flex flex-col gap-2 md:flex-1 md:flex-row">
                <TextField
                  className="w-full"
                  id="name"
                  label="Name"
                  name="name"
                  variant="filled"
                  value={user?.firstName + " " + user?.lastName}
                  disabled={true}
                />
                <TextField
                  className="w-full"
                  id="email"
                  label="Email"
                  name="email"
                  variant="filled"
                  value={user?.email}
                  type="email"
                  disabled={true}
                />
              </div>
              <div>
                {permissionError ? (
                  <Alert severity="error">
                    Please allow access to the camera and microphone to start
                    the test.
                  </Alert>
                ) : (
                  <button
                    disabled={!cameraAccessGranted}
                    onClick={handleSubmit}
                    className="transition-all w-full px-5 py-3 bg-purple-500 mt-4 font-bold text-lg text-white hover:bg-purple-700 disabled:hover:bg-purple-300 disabled:bg-purple-300"
                  >
                    Start the test
                  </button>
                )}
              </div>
            </form>
          </div>
        )}
      </Container>
    </>
  );
};

export default StartTest;
